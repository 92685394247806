<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="edit">
        <profileForm v-if="edit === false" :saveData="saveData" :onChange="function(type) { edit = type }" />
        <changeEmail v-if="edit === 'email'" :saveData="saveData" :onChange="function(type) { edit = false }" />
        <changeTel v-if="edit === 'tel'" :saveData="saveData" :onChange="function(type) { edit = false }" />
        <changeVat v-if="edit === 'vat'" :saveData="saveData" :onChange="function(type) { edit = false }" />
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    profileForm () { return import('@/components/profile/form') },
    changeEmail () { return import('@/components/profile/changeEmail') },
    changeVat () { return import('@/components/profile/changeVat') },
    changeTel () { return import('@/components/profile/changeTel') }
  },
  props: {
    saveData: Function
  },
  data () {
    return {
      edit: false
    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
